import React from 'react';
import '../../scss/docslaw.scss';

const DocsLaws = ({ lawId }) => {
    return (
        <div className={"docs-law"}>
            <iframe title={`Gesetz ${lawId}`} src={`https://docs.google.com/document/d/e/${lawId}/pub?embedded=true`} width="800px" style={{textAlign: "end"}} frameBorder="0" />
        </div>
    );
};

export default DocsLaws;
