import './scss/main.scss';
import MainPage from "./pages/MainPage/main";

function App() {
  return (
      <>
        <MainPage/>
      </>
  );
}

export default App;
