import '../../scss/lawText.scss'
import {useEffect} from "react";
import ReactGA from "react-ga";
import Cookies from "js-cookie";
function TermsOfUse() {

    useEffect(() => {
        const cookieConsent = Cookies.get('cookieConsent');

        if (cookieConsent === 'accepted') {
            ReactGA.initialize(process.env.REACT_APP_API_KEY);
            ReactGA.pageview('/TermsOfUse');
        }
    }, []);

    return (
        <div className={"law-text"}>
            <h1>Nutzungsbedingungen</h1>
            <p>
                Willkommen auf unserer Website <a
                href="http://sali.devdynasty.studio">sali.sentinel-gta.cloud</a> (nachfolgend
                "SALI"). Durch die Nutzung dieser Website erklären Sie sich mit den
                folgenden Nutzungsbedingungen (nachfolgend "Bedingungen") einverstanden.
            </p>
            <h2>1. Zugang zur Website</h2>
            <p>
                <strong>1.1. Zugangsbeschränkung:</strong> Diese Website ist nur für
                Personen bestimmt, die von uns ausdrücklich dazu eingeladen wurden oder
                die auf andere Weise autorisiert sind. Der Zugang zur Website kann
                beschränkt sein und erfordert möglicherweise eine Anmeldung oder ein
                Passwort.
            </p>
            <p>
                <strong>1.2. Alter:</strong> Sie müssen mindestens 16 Jahre
                alt sein, um diese Website zu nutzen. Durch die Nutzung der Website
                bestätigen Sie, dass Sie das erforderliche Mindestalter erreicht haben.
            </p>
            <h2>2. Verhaltensregeln</h2>
            <p>
                <strong>2.1. Verbotene Handlungen:</strong> Bei der Nutzung dieser
                Website erklären Sie sich damit einverstanden, keine Inhalte hochzuladen,
                zu teilen oder auf andere Weise bereitzustellen, die gegen geltendes
                Recht verstoßen, obszön, beleidigend, diffamierend, bedrohlich oder
                anderweitig unangemessen sind.
            </p>
            <p>
                <strong>2.2. Geistiges Eigentum:</strong> Alle auf der Website angezeigten
                Inhalte, einschließlich Texte, Bilder, Grafiken, Logos und andere
                Materialien, sind urheberrechtlich geschützt und gehören uns oder unseren
                Lizenzgebern. Sofern nicht anders angegeben, sind Gesetzestexte und andere
                Inhalte, die nicht von uns erstellt wurden, urheberrechtlich geschützt und
                unterliegen den jeweiligen Urheberrechtsbestimmungen.
            </p>
            <h2>3. Haftungsausschluss</h2>
            <p>
                <strong>3.1. Die Nutzung dieser Website erfolgt auf eigene Gefahr.</strong> Wir übernehmen keine Haftung
                für Schäden, die durch die Nutzung der
                Website entstehen.
            </p>
            <p>
                <strong>3.2. Wir behalten uns das Recht vor, den Zugang zur Website nach
                    eigenem Ermessen jederzeit zu beschränken, zu sperren oder zu beenden.</strong>
            </p>
            <h2>4. Discord-Bots und Dashboard</h2>
            <p>
                <strong>4.1. Nutzung von Discord-Bots und Dashboard:</strong> Unsere Website verwendet Discord-Bots und
                ein Discord-Dashboard zur Bereitstellung und Verwaltung von Diensten. Bei der Nutzung dieser Funktionen
                werden personenbezogene Daten verarbeitet, einschließlich, aber nicht beschränkt auf, Benutzernamen, IDs
                und Transaktionsdaten.
            </p>
            <p>
                <strong>4.2. Verantwortung und Haftung:</strong> Sie stimmen zu, dass die Nutzung unserer Discord-Bots
                und des Dashboards gemäß den jeweiligen Discord-Nutzungsbedingungen und Datenschutzrichtlinien erfolgt.
                Wir übernehmen keine Haftung für Schäden, die aus der Nutzung der Discord-Bots oder des Dashboards
                entstehen.
            </p>
            <h2>5. Änderungen der Nutzungsbedingungen</h2>
            <p>
                <strong>5.1. Wir behalten uns das Recht vor, diese Bedingungen jederzeit zu ändern.</strong> Es liegt in
                Ihrer Verantwortung, regelmäßig auf Aktualisierungen der Bedingungen zu achten.
            </p>
            <h2>6. Schlussbestimmungen</h2>
            <p>
                <strong>6.1. Diese Bedingungen unterliegen dem deutschen Recht.</strong>
            </p>
            <p>
                <strong>6.2. Bei Fragen oder Bedenken bezüglich dieser Bedingungen oder der Website können Sie uns unter Discord: verenacv oder über <a href="mailto:info@devdynasty.studio">info@devdynasty.studio</a> erreichen.</strong>
            </p>
        </div>
    );
}

export default TermsOfUse;