import '../../scss/lawText.scss'
import {useEffect} from "react";
import ReactGA from "react-ga";
import Cookies from "js-cookie";
function CookieImprint() {

    useEffect(() => {
        const cookieConsent = Cookies.get('cookieConsent');

        if (cookieConsent === 'accepted') {
            ReactGA.initialize(process.env.REACT_APP_API_KEY);
            ReactGA.pageview('/cookies');
        }
    }, []);

    return (
        <div className="law-text">
            <h1>Cookie-Richtlinie</h1>
            <p><strong>Letzte Aktualisierung:</strong> 11.10.2023</p>
            <br/>

            <p>Diese Cookie-Richtlinie erklärt, wie unsere Webseite Cookies und ähnliche Tracking-Technologien verwendet. Bitte lesen Sie diese Richtlinie sorgfältig durch, um zu verstehen, wie wir Cookies verwenden und wie Sie Ihre Cookie-Einstellungen verwalten können.</p>

            <h2>Was sind Cookies?</h2>

            <p>Cookies sind kleine Textdateien, die auf Ihrem Computer oder Mobilgerät gespeichert werden, wenn Sie unsere Webseite besuchen. Sie ermöglichen es unserer Webseite, bestimmte Informationen über Ihren Besuch zu speichern, um Ihre Erfahrung zu verbessern und unsere Webseite effizienter zu gestalten.</p>

            <h2>Welche Arten von Cookies verwenden wir?</h2>

            <ol>
                <li>
                    <strong>Notwendige Cookies:</strong> Diese Cookies sind unerlässlich, damit Sie sich auf unserer Webseite bewegen und ihre Funktionen nutzen können. Sie werden in der Regel nur als Reaktion auf von Ihnen getätigte Aktionen gesetzt, die einer Anforderung von Diensten gleichkommen, z. B. das Festlegen Ihrer Datenschutzeinstellungen, Anmelden oder Ausfüllen von Formularen. Sie können in Ihrem Browser-Einstellungen blockiert werden, aber einige Teile der Webseite werden dann nicht funktionieren.
                </li>
                {/*<li>*/}
                {/*    <strong>Leistungs-Cookies:</strong> Diese Cookies sammeln Informationen darüber, wie Besucher unsere Webseite nutzen. Sie helfen uns zu verstehen, wie Besucher mit Seiten interagieren, welche Bereiche der Webseite am häufigsten besucht werden und ob Fehlermeldungen angezeigt werden. Diese Cookies sammeln keine Informationen, die Sie identifizieren. Alle Informationen, die diese Cookies sammeln, sind aggregiert und daher anonym.*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <strong>Funktionalitäts-Cookies:</strong> Diese Cookies ermöglichen unserer Webseite, sich an von Ihnen getroffene Entscheidungen (z. B. Ihren Benutzernamen, Sprache oder die Region, in der Sie sich befinden) zu erinnern, und bieten erweiterte, persönlichere Funktionen.*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <strong>Marketing-Cookies:</strong> Diese Cookies werden verwendet, um Werbung relevanter für Sie und Ihre Interessen zu machen. Sie werden auch dazu verwendet, die Anzahl der Anzeigenaufrufe zu begrenzen und die Effektivität von Werbekampagnen zu messen. Diese Cookies können von Werbeunternehmen über unsere Webseite gesetzt werden.*/}
                {/*</li>*/}
            </ol>

            <h2>Wie verwenden wir Cookies?</h2>

            <h2>Wir verwenden Cookies, um:</h2>
            <ol>
                <li>Ihre Präferenzen und Einstellungen zu speichern.</li>
                <li>Analysen durchzuführen und Leistungsdaten zu verfolgen.</li>
                <li>Die Effektivität unserer Marketingkampagnen zu bewerten.</li>
            </ol>

            <h2>Wie können Sie Cookies verwalten?</h2>

            <p>Sie können Cookies über die Einstellungen Ihres Webbrowsers verwalten. Beachten Sie jedoch, dass das Deaktivieren von Cookies die Funktionalität und Benutzerfreundlichkeit unserer Webseite beeinträchtigen kann. Je nach Ihrem Browser können Sie Cookies löschen, blockieren oder überwachen. Weitere Informationen finden Sie in den Hilfe- oder Support-Bereichen Ihres Browsers.</p>

            <h2>Cookies von Drittanbietern</h2>

            <p>Wir verwenden Drittanbieter-Dienste wie Google Analytics, um Informationen darüber zu sammeln, wie unsere Webseite genutzt wird. Diese Dienste verwenden Cookies, um Informationen über Ihre Interaktion mit unserer Webseite zu sammeln. Sie können die Datenschutzrichtlinie von Google Analytics unter [Link zur Datenschutzrichtlinie von Google Analytics] einsehen.</p>

            <h2>Änderungen an dieser Richtlinie</h2>

            <p>Wir behalten uns das Recht vor, diese Cookie-Richtlinie zu ändern. Jede Aktualisierung wird auf dieser Seite veröffentlicht und das Datum der letzten Aktualisierung am Anfang der Richtlinie wird aktualisiert.</p>

            <h2>Kontakt</h2>

            <p>Wenn Sie Fragen zu dieser Cookie-Richtlinie haben, kontaktieren Sie uns bitte unter [Kontaktinformationen].</p>

        </div>
    )
}

export default CookieImprint;