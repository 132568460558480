import React, {useEffect, useState} from 'react';
import "../../scss/main.scss";
import navConfig from "../../configs/navConfig.json";
import FineCatalog from "../FineCatalog/main";
import "../../scss/cookies.scss";
import CookieNotice from "../../components/CookieNotice";
import Cookies from 'js-cookie';
import CookieImprint from "../LegalNotice/CookieImprint";
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import ReactGA from "react-ga";
import {KofiButton} from "react-kofi-button";
import LawerList from "../LawyerList/main";
import Imprint from "../Imprint/Imprint";
import DSGVO from "../LegalNotice/DSGVO";
import TermsOfUse from "../LegalNotice/TermsOfUse";
import DocsLaws from "../DocsLaws/DocsLaws";

function MainPage() {
    const [currentComponent, setCurrentComponent] = useState(navConfig.nav["Global Archive"][0]);
    const [searchTerm, setSearchTerm] = useState("");

    const [showCookieNotice, setShowCookieNotice] = useState(false); // Zustand für die Anzeige der CookieNotice

    let sectionTitle = '';
    Object.entries(navConfig.nav).every(([sectionKey, sectionValues]) => {
        return sectionValues.every(section => {
            if (section.title === currentComponent.title) {
                sectionTitle = sectionKey;
                return false;
            }
            return true;
        });
    });

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        Object.entries(navConfig.nav).every(([sectionKey, sectionValues]) => {
            return sectionValues.every(section => {
                if (section.path === window.location.pathname) {
                    setCurrentComponent(section);
                    return false;
                }
                return true;
            });
        });
    }, []);

    const filteredNavSections = Object.entries(navConfig.nav).map(([sectionKey, sectionValues]) => {
        const filteredNavItems = sectionValues.filter(navItem =>
            navItem.title.toLowerCase().includes(searchTerm.toLowerCase())
        ).map(navItem => {

            return (
                <li key={navItem.title} className="nav-item">
                    <a
                        className={`nav-link ${window.location.pathname === navItem.path ? 'active' : ''} ${navItem.disabled === 'true' ? 'disabled' : ''}`}
                        href={navItem.path}
                    >
                        <i className={navItem.iconClass}/>
                        <span className={'sidebar-link-title'} title={navItem.title}>{navItem.title}</span>
                    </a>
                </li>
            );
        });

        if (filteredNavItems.length === 0) {
            return null;
        }

        return (
            <React.Fragment key={sectionKey}>
                <li className="nav-heading">{sectionKey}</li>
                <li className="nav-content">
                    <ul>{filteredNavItems}</ul>
                </li>
            </React.Fragment>
        );
    });

    useEffect(() => {
        const cookieConsent = Cookies.get('cookieConsent');
        if (!cookieConsent) {
            setShowCookieNotice(true);
        }
    }, []);

    useEffect(() => {
        const cookieConsent = Cookies.get('cookieConsent');

        if (cookieConsent === 'accepted') {
            ReactGA.initialize(process.env.REACT_APP_API_KEY);
            ReactGA.pageview('/');
        }
    }, []);

    const acceptCookies = () => {
        Cookies.set('cookieConsent', 'accepted', {expires: 365});
        setShowCookieNotice(false);
    };
    const rejectCookies = () => {
        Cookies.set('cookieConsent', 'rejected', {expires: 365});
        setShowCookieNotice(false);
    };

    return (
        <>
            {showCookieNotice && (
                <CookieNotice onAccept={acceptCookies} onReject={rejectCookies}/>
            )}
            <div className="main-page">
                <div className="sidebar">
                    <aside id="sidebar" className="sidebar-content">
                        <div className="sidebar-section">
                            <div className="sidebar-title">
                                <div className="sidebar-title-flex">
                                    S.A.L.I
                                </div>
                                <div className="sidebar-subtitle">SAN ANDREAS LEGAL INDEX</div>
                            </div>
                            <img className="sidebar-image" src={`${window.location.origin + "/logo.png"}`}/>
                        </div>
                        {/*<div>*/}
                        {/*    <input*/}
                        {/*        type="text"*/}
                        {/*        className="search-bar"*/}
                        {/*        placeholder="Suchen..."*/}
                        {/*        maxLength="20"*/}
                        {/*        value={searchTerm}*/}
                        {/*        onChange={handleSearchChange}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <ul id="sidebar-nav" className="sidebar-nav">
                            {filteredNavSections}
                        </ul>

                        <footer className="footer">
                            <KofiButton
                                username="verenacv"
                                label="Support me"
                                preset="skinny"
                                backgroundColor="kofiGrey"
                            />
                            <div className="copyright">
                                &copy; Copyright <strong>DevDynasty Studios</strong>.
                                <br/>Maintained by <strong>Verena Verlice</strong>
                            </div>
                            <div className={"cookie-links"}>
                                <a className={"cookieLink"} href={"/cookies"}>Cookie-Richtlinien
                                </a>
                                <a className={"cookieLink"} href={"/imprint"}>Impressum</a>
                                <a className={"cookieLink"} href={"/terms"}>Nutzungsbedingungen</a>
                                <a className={"cookieLink"} href={"/dsgvo"}>Datenschutzerklärung</a>
                            </div>
                        </footer>
                    </aside>
                </div>
                <div className="navbar">
                    <div className="navbar-main">
                        <div className="page-title">
                            <h1 id="title-of-page" className="page-wrap title">{currentComponent.title}</h1>
                            <div className="breadcrumb">
                                <div className="breadcrumb-item">
                                    <div className="category" id="category-of-page">
                                        {sectionTitle}
                                    </div>
                                    <div className="divider"> /</div>
                                    <div id="subtitle-of-page" className="page-wrap subtitle">
                                        {currentComponent.title}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'breadcrumb-date'}>Zuletzt aktualisiert:
                            <div className="date">
                                {currentComponent.date === 'ECHTZEIT' ?
                                    new Date().toLocaleString('de-DE', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                    })
                                    :
                                    currentComponent.date
                                }
                            </div>
                        </div>
                    </div>
                    <div className="navbar-miranda">
                        <p>
                            Sie haben das Recht zu schweigen. Alles, was Sie sagen, kann und wird vor Gericht gegen Sie verwendet werden. Sie haben das Recht, zu jeder Vernehmung einen Verteidiger hinzuzuziehen. Wenn Sie sich keinen Verteidiger leisten können, wird Ihnen einer gestellt.
                        </p>
                    </div>
                </div>
                <div className="main-section">
                    <BrowserRouter>
                        <Routes>
                            <Route path={'/'} element={<FineCatalog/>}/>
                            {/*<Route path={'/ampel'} element={<JudiciaryLight/>}/>*/}
                            <Route path={'/anwaltsliste'} element={<LawerList/>}/>
                            <Route path={'/strafgesetzbuch'} element={<DocsLaws
                                lawId={"2PACX-1vSVCsmE8Z9WnYm_X6o217HIftCZfA_d94oa5-ByLPj4y1PfzSGemo5JmxmG3GMCRxSXSCgkAUy1JdX7"}/>}/>
                            <Route path={'/beamtendienstgesetz'} element={<DocsLaws
                                lawId={"2PACX-1vSD_maxq9rTNg7EsogQF0tZAQWtYx8gMWG9ycEyf5Hm_bYGC_XFqtdHNdWsY5BEbOyIkBvKujoovkgV"}/>}/>
                            <Route path={'/militaerstrafgesetz'} element={<DocsLaws
                                lawId={`2PACX-1vRsb8krdewzk14_1pfRiyCGyVrd3gt_wyioVf5fnitD3wc-eZ3CYOKYgiVhZOu5hDU6d67FzaW_jPgI`}/>}/>
                            <Route path={'/arbeitsschutzgesetz'} element={<DocsLaws
                                lawId={`2PACX-1vTW3RbLJkZ1XCD4fjYrnHCXXyXMMazlVtGQb9NdyMn4Kl5OjX7B_42D18kPPcqSsny3FaYnq-H--rAp`}/>}/>
                            <Route path={'betaeubungsmittelgesetz'} element={<DocsLaws
                                lawId={`2PACX-1vSnnsdhrR_K7rqxik7TY7Qjet9QK1NG-jnALvESwY20P0LRGZpFedafM3YeOseZV1pSE-Bh9nhWD0-t`}/>}/>
                            <Route path={'/buergerliches-gesetzbuch'} element={<DocsLaws
                                lawId={`2PACX-1vRO4k0uIf0OoilcgLW5pRwuswH2UD2zTT4TdIr-i7SXejcCcEvROgsqpuDAgIU8-kmZOirOc9HabJg2`}/>}/>
                            <Route path={'/gewerbeordnung'} element={<DocsLaws
                                lawId={`2PACX-1vTP4ftHIN2T9RDra9HoxpmxCHvGhImUMRgr018RgAfaOJkXzLhX4YkRxOpJb1gt4c_klw9QJerhgtq5`}/>}/>
                            <Route path={'/steuergesetz'} element={<DocsLaws
                                lawId={`2PACX-1vSRNEhUmtXyW2A1bWfivajHauDzWKGF8vzMW71zryIOLU-w5mk335Mu61CtJn252u4kqF-p_5LozHRd`}/>}/>
                            <Route path={'/strafprozessordnung'} element={<DocsLaws
                                lawId={`2PACX-1vQsVBi_MxXiDHDQHo3p7citMDwNKMeghyzvkyh0s2jfaDBQuAi-pEHD9BkHQViptgyIXn5pRL5AxPMS`}/>}/>
                            <Route path={'/straßenverkehrsordnung'} element={<DocsLaws
                                lawId={`2PACX-1vQRKbwY0MpOVujhUxDouPUZCmLGHvQ5Bh5jSh9rCyb9uIvmZIBVm6I1GpTKG4pFnvYzRXxaGKcJBu54`}/>}/>
                            <Route path={'/verfassung-des-staates-san-andreas'} element={<DocsLaws
                                lawId={`2PACX-1vR92BCXWg2frDYbBhoS9zC0zrYv2j77q3WzX0hkf1_UDteGLFhMsVuUlVgH7JtkkmH2LAjnnGojx2g2`}/>}/>
                            <Route path={'/anwaltsordnung'} element={<DocsLaws
                                lawId={`2PACX-1vRt0vt-sUVMZMn2ybQ_LNzmFrlRAr3-jBrPf8G6AKqUZ4kwhrBEuXYT5-1-6YLrNyDsdGPYbeOgn6JB`}/>}/>
                            <Route path={'/waffengesetz'} element={<DocsLaws
                                lawId={`2PACX-1vSndTiK2r1XYNMBHtfbFhRY4fy4j8H5yjjKTuIXE1nr9n1AWazR2Relwf7QuMJd1t8M6zhbwEiPUAgf`}/>}/>
                            <Route path={'/cookies'} element={<CookieImprint/>}/>
                            <Route path={'/imprint'} element={<Imprint/>}/>
                            <Route path={'/dsgvo'} element={<DSGVO/>}/>
                            <Route path={'/terms'} element={<TermsOfUse/>}/>
                        </Routes>
                    </BrowserRouter>
                </div>
            </div>
        </>
    );
}

export default MainPage;
