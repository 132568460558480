import '../../scss/lawText.scss'
import {useEffect} from "react";
import ReactGA from "react-ga";
import Cookies from "js-cookie";
function DSGVO() {

    useEffect(() => {
        const cookieConsent = Cookies.get('cookieConsent');

        if (cookieConsent === 'accepted') {
            ReactGA.initialize(process.env.REACT_APP_API_KEY);
            ReactGA.pageview('/dsgvo');
        }
    }, []);

    return (
        <div className={'law-text'}>
            <h1>Datenschutz­erklärung</h1>
            <h2>1. Datenschutz auf einen Blick</h2>
            <h3>Allgemeine Hinweise</h3>
            <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
                passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie
                unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
            <h3>Datenerfassung auf dieser Website</h3>
            <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
            <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können
                Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.</p>
            <h4>Wie erfassen wir Ihre Daten?</h4>
            <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um
                Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
            <p>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere
                IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder
                Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
                betreten.</p>
            <h4>Wofür nutzen wir Ihre Daten?</h4>
            <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
                Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
            <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
            <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
                Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
                können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
                bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
                Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>
            <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</p>
            <h3>Analyse-Tools und Tools von Dritt­anbietern</h3>
            <p>Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor
                allem mit sogenannten Analyseprogrammen.</p>
            <p>Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden
                Datenschutzerklärung.</p>
            <h2>2. Hosting</h2>
            <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
            <h3>Hetzner</h3>
            <p>Anbieter ist die Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (nachfolgend Hetzner).</p>
            <p>Details entnehmen Sie der Datenschutzerklärung von Hetzner: <a
                href="https://www.hetzner.com/de/rechtliches/datenschutz" target="_blank"
                rel="noopener noreferrer">https://www.hetzner.com/de/rechtliches/datenschutz</a>.</p>
            <p>Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein
                berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine
                entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
                Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies
                oder den Zugriff auf Informationen im Endgerät des Nutzers (z.B. Device-Fingerprinting) im Sinne des
                TDDDG umfasst. Die Einwilligung ist jederzeit widerrufbar.</p>
            <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
            <h3>Datenschutz</h3>
            <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie
                dieser Datenschutzerklärung.</p>
            <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene
                Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
                Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch,
                wie und zu welchem Zweck das geschieht.</p>
            <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                nicht möglich.</p>
            <h3>Hinweis zur verantwortlichen Stelle</h3>
            <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
            <p>Veit Böttcher<br/>
                c/o IP-Management #34240<br/>
                Ludwig-Erhard-Str. 18<br/>
                20459 Hamburg</p>
            <p>
                E-Mail: info@devdynasty.studio</p>
            <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen
                über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen
                o.Ä.) entscheidet.</p>
            <h3>Speicherdauer</h3>
            <p>Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben
                Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
                berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
                werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung
                Ihrer personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
                letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.</p>
            <h3>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h3>
            <p>Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
                Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
                Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung
                in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf
                Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff
                auf Informationen in Ihr Endgerät (z.B. via Device-Fingerprinting) eingewilligt haben, erfolgt die
                Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TDDDG. Die Einwilligung ist jederzeit
                widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen
                erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren
                verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind
                auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres
                berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall
                einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung
                informiert.</p>
            <h3>Empfänger von personenbezogenen Daten</h3>
            <p>Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen externen Stellen zusammen. Dabei ist
                teilweise auch eine Übermittlung von personenbezogenen Daten an diese externen Stellen erforderlich. Wir
                geben personenbezogene Daten nur dann an externe Stellen weiter, wenn dies im Rahmen einer
                Vertragserfüllung erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind (z.B. Weitergabe von
                Daten an Steuerbehörden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der
                Weitergabe haben oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von
                Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden nur auf Grundlage eines gültigen
                Vertrags über Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung wird ein Vertrag über
                gemeinsame Verarbeitung geschlossen.</p>
            <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
            <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
                bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                Datenverarbeitung bleibt vom Widerruf unberührt.</p>
            <h3>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21
                DSGVO)</h3>
            <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE
                JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
                VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIESES GELTET AUCH FÜR EIN AUF DIESE
                BESTIMMUNGEN GESTÜTZTES PROFILING.</p>
            <p>WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI
                DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN,
                RECHTE UND FREIHEITEN ÜBERWIEGEN, ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
                VERTEIDIGUNG VON RECHTSANSPRÜCHEN.</p>
            <p>WENN IHRE PERSONENBEZOGENEN DATEN VERARBEITET WERDEN, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT,
                JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG DER SIE BETREFFENDEN PERSONENBEZOGENEN DATEN ZUM ZWECK
                DERARTIGER WERBUNG EINZULEGEN; DAS GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
                VERBINDUNG STEHT.</p>
            <p>WENN SIE WIDERSPRUCH EINLEGEN, WERDEN IHRE PERSONENBEZOGENEN DATEN NICHT MEHR FÜR DEN ZWECK DER
                DIREKTWERBUNG VERARBEITET.</p>
            <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
            <p>Im Falle von Verstößen gegen die DSGVO steht den betroffenen Personen ein Beschwerderecht bei einer
                Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
                oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderer
                verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
            <h2>4. Datenerfassung auf dieser Website</h2>
            <h3>Cookies</h3>
            <p>Unsere Internetseiten verwenden so genannte Cookies. Cookies sind kleine Textdateien und richten auf
                Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (sog.
                Sitzungs-Cookies) oder dauerhaft (sog. permanente Cookies) auf Ihrem Endgerät gespeichert.
                Sitzungs-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf
                Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren
                Webbrowser erfolgt.</p>
            <p>In einigen Fällen werden auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert, wenn Sie
                unsere Seite betreten (Drittanbieter-Cookies). Diese ermöglichen es uns oder Ihnen, die Dienste des
                Drittunternehmens zu nutzen (z.B. Cookies zur Durchführung von Zahlungsdiensten).</p>
            <p>Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
                Websitefunktionen ohne diese nicht funktionieren würden (z.B. die Warenkorbfunktion oder die Anzeige von
                Videos). Andere Cookies dienen dazu, das Nutzungsverhalten auszuwerten oder Werbung anzuzeigen.</p>
            <p>Die Verarbeitung von Daten, die durch Cookies erfolgen, ist auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
                gerechtfertigt. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur
                technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine entsprechende
                Einwilligung abgefragt wurde (z.B. eine Einwilligung zur Speicherung von Cookies), erfolgt die
                Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TDDDG. Die
                Einwilligung ist jederzeit widerrufbar.</p>
            <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
                Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
                ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der
                Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.</p>
            <h3>Server-Log-Dateien</h3>
            <p>Der Anbieter der Seiten erhebt und speichert automatisch Informationen in so genannten
                Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
            <ul>
                <li>Browsertyp und Browserversion</li>
                <li>Verwendetes Betriebssystem</li>
                <li>Referrer URL</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Uhrzeit der Serveranfrage</li>
            </ul>
            <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen
                Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu überprüfen, wenn
                uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.</p>
            <h3>Kontaktformular</h3>
            <p>Wenn Sie uns über das Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
                Anfrageformular einschließlich der von Ihnen dort angegebenen Kontaktdaten zur Bearbeitung der Anfrage
                und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
                Einwilligung weiter.</p>
            <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage
                mit der Erfüllung eines Vertrages zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
                erforderlich ist. In allen übrigen Fällen basiert die Verarbeitung auf unserem berechtigten Interesse an
                der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), sofern diese abgefragt wurde.</p>
            <p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
                auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenverarbeitung
                entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
                insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.</p>
            <h3>Registrierung auf dieser Website</h3>
            <p>Sie können sich auf dieser Website registrieren, um zusätzliche Funktionen auf der Seite zu nutzen. Die
                übermittelten Daten verwenden wir ausschließlich zum Zweck der Nutzung des jeweiligen Angebots oder
                Dienstes, für den Sie sich registriert haben. Die bei der Registrierung abgefragten Pflichtangaben
                müssen vollständig gemacht werden. Anderenfalls werden wir die Registrierung ablehnen.</p>
            <p>Bei wichtigen Änderungen, die den Umfang der Registrierung betreffen oder technisch notwendig werden
                (z.B. bei der Änderung des Angebots oder der technischen Maßnahmen), nutzen wir die bei der
                Registrierung angegebenen Kontaktdaten, um Sie auf diesem Weg zu informieren.</p>
            <p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung
                (Art. 6 Abs. 1 lit. a DSGVO). Sie können Ihre Einwilligung jederzeit widerrufen. In diesem Fall werden
                Ihre Daten gelöscht, sofern keine anderen gesetzlichen Aufbewahrungspflichten bestehen. Wenn Ihre Daten
                zur Erfüllung eines Vertrages oder zur Durchführung vorvertraglicher Maßnahmen erforderlich sind,
                erfolgt die Verarbeitung zusätzlich auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO.</p>
            <h2>5. Analyse-Tools und Werbung</h2>
            <h3>Google Analytics</h3>
            <p>Diese Website nutzt den Dienst Google Analytics, um die Nutzung der Website zu analysieren. Anbieter ist
                die Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
            <p>Google Analytics verwendet Cookies. Die durch das Cookie erzeugten Informationen über Ihre Benutzung
                dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort
                gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website wird Ihre IP-Adresse
                jedoch von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
                des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.</p>
            <p>Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort
                gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre
                Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere
                mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem
                Websitebetreiber zu erbringen.</p>
            <p>Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten
                von Google zusammengeführt. Die Speicherung von Cookies können Sie durch eine entsprechende Einstellung
                Ihrer Browser-Software verhindern; wir weisen jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
                nicht alle Funktionen dieser Website vollumfänglich werden nutzen können.</p>
            <p>Die Verarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sein Webangebot sowie seine Werbung zu
                optimieren.</p>
            <p>Details entnehmen Sie der Datenschutzerklärung von Google: <a
                href="https://policies.google.com/privacy?hl=de" target="_blank"
                rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
            <h3>Discord Bots und Dashboard</h3>
            <p>Wir nutzen Discord-Bots und ein Discord-Dashboard für verschiedene Zwecke, einschließlich der
                Registrierung und Anmeldung sowie zur Monetarisierung. Diese Bots verwenden den Discord
                OAuth-Mechanismus zur Authentifizierung und zur Verarbeitung von Nutzerdaten.</p>
            <p>Bei der Verwendung von Discord Bots und Dashboard werden folgende Daten verarbeitet:</p>
            <ul>
                <li>Benutzername und ID</li>
                <li>Server-IDs, auf denen der Bot installiert ist</li>
                <li>Eventuelle Zahlungen und Transaktionsdaten für Monetarisierung</li>
            </ul>
            <p>Diese Daten werden genutzt, um die Dienste auf Discord anzubieten und zu verwalten. Die Verarbeitung
                erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO zur Erfüllung von Verträgen oder vorvertraglichen
                Maßnahmen und Art. 6 Abs. 1 lit. f DSGVO für berechtigte Interessen an der Bereitstellung und
                Verbesserung unserer Discord-Dienste.</p>
            <p>Die Datenschutzerklärung von Discord finden Sie hier: <a href="https://discord.com/privacy"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer">https://discord.com/privacy</a>.
            </p>
            <h2>6. Plugins und Tools</h2>
            <h3>Discord OAuth</h3>
            <p>Für die Registrierung und Anmeldung auf unserer Website nutzen wir den Discord OAuth-Dienst. Dieser
                Dienst ermöglicht es Ihnen, sich über Ihren Discord-Account zu registrieren und einzuloggen.</p>
            <p>Bei Nutzung von Discord OAuth werden folgende Daten verarbeitet:</p>
            <ul>
                <li>Benutzername und ID</li>
                <li>Token zur Authentifizierung</li>
            </ul>
            <p>Die Verarbeitung erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO.</p>
            <h2>7. Änderung unserer Datenschutz­erklärung</h2>
            <p>Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit sie stets den aktuellen
                rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung
                umzusetzen, z.B. bei der Einführung neuer Dienste. Für Ihren erneuten Besuch gilt dann die neue
                Datenschutzerklärung.</p>
            <h2>8. Fragen zum Datenschutz</h2>
            <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail an:
                info@devdynasty.studio.</p>
        </div>
    )
}

export default DSGVO;