import React, { useEffect, useState, useCallback } from 'react';
import '../../scss/fines.scss';
import ReactGA from 'react-ga';
import Cookies from 'js-cookie';
import axios from 'axios';

const throttle = (func, limit) => {
    let inThrottle;
    return function () {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
    };
};

function LawyerList() {
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const cookieConsent = Cookies.get('cookieConsent');
        if (cookieConsent === 'accepted') {
            ReactGA.initialize(process.env.REACT_APP_API_KEY);
            ReactGA.pageview('/lawyerlist');
        }
    }, []);

    useEffect(() => {
        const getGoogleSheetsData = async () => {
            try {
                const response = await axios.get(
                    `https://sheets.googleapis.com/v4/spreadsheets/${process.env.REACT_APP_GOOGLE_SHEETS_ID}/values/A2:L100?key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                );
                setData(response.data.values);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data', error);
                setError(error);
                setLoading(false);
            }
        };

        getGoogleSheetsData();
    }, []);

    const handleSearch = useCallback(
        throttle(() => {
            const searchRegex = new RegExp(searchTerm, 'gi');
            const paragraphs = document.querySelectorAll('tr.lawyer');
            const foundParagraphs = [];
            const paragraphsToHide = [];

            ReactGA.event({
                category: 'Interactions',
                action: 'Search',
                label: `A user searched in table`,
            });

            paragraphs.forEach((paragraph) => {
                const text = paragraph.innerText;
                if (searchTerm && searchRegex.test(text)) {
                    foundParagraphs.push(paragraph);
                } else {
                    paragraphsToHide.push(paragraph);
                }
            });

            if (!searchTerm) {
                paragraphsToHide.length = 0;
                foundParagraphs.push(...paragraphs);
            }

            paragraphsToHide.forEach((paragraph) => {
                paragraph.style.display = 'none';
            });

            foundParagraphs.forEach((paragraph) => {
                paragraph.style.display = 'table-row';
            });
        }, 1000),
        [searchTerm]
    );

    useEffect(() => {
        handleSearch();
    }, [searchTerm, handleSearch]);

    if (loading) {
        return (
            <div className="fine-table">
                <table>
                    <thead>
                        <tr>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Loading...</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    if (error) {
        return (
            <div className="fine-table">
                <table>
                    <thead>
                        <tr>
                            <th>Error</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{error.message}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className="fine-table">
            <div className="filter">
                <input
                    type="text"
                    className="filter-search-bar"
                    placeholder="Suchen..."
                    maxLength="20"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Vorname</th>
                        <th>Nachname</th>
                        <th>Telefon</th>
                        <th>Lizenz</th>
                        <th>Gesperrt</th>
                        <th>Ausgestellt (erneuert)</th>
                        <th>Gültig bis</th>
                        <th>Anwaltsform</th>
                        <th>Kanzlei / Fraktion</th>
                        <th>Pflichtverteidigung übernommen</th>
                        <th>Zulassung</th>
                        <th>Kommentar</th>
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        data.map((row, index) => (
                            <tr key={index} className="no-break-table lawyer">
                                <td>{row[0]}</td>
                                <td>{row[1]}</td>
                                <td>{row[2]}</td>
                                <td>{row[3]}</td>
                                <td>{row[4]}</td>
                                <td>{row[5]}</td>
                                <td>{row[6]}</td>
                                <td>{row[7]}</td>
                                <td>{row[8]}</td>
                                <td>{row[9]}</td>
                                <td>{row[10]}</td>
                                <td>{row[11]}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}

export default LawyerList;
