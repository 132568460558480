import '../../scss/lawText.scss'
import {useEffect} from "react";
import ReactGA from "react-ga";
import Cookies from "js-cookie";
function Imprint() {

    useEffect(() => {
        const cookieConsent = Cookies.get('cookieConsent');

        if (cookieConsent === 'accepted') {
            ReactGA.initialize(process.env.REACT_APP_API_KEY);
            ReactGA.pageview('/imprint');
        }
    }, []);

    return (
        <div className={'law-text'}>
            <h1>Impressum</h1>
            <span>DevDynasty Studios</span>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>Veit Böttcher<br/>
                c/o IP-Management #34240<br/>
                Ludwig-Erhard-Str. 18<br/>
                20459 Hamburg<br/>
            </p><br/>

            <p className="rights-disclaimer">Sollte diese Adresse missbraucht werden, bleibt der Rechtsweg nicht ausgeschlossen.</p>

            <h2>Kontakt</h2>
            <p>E-Mail: info@devdynasty.studio</p>

            <h2>EU-Streitschlichtung</h2>
            <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.</p>
            <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

            <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

            <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
        </div>
    )
}

export default Imprint;