import React from "react";

function CookieNotice({ onAccept, onReject }) {
    return (
        <div className="card">
            <i className="bi bi-cookie"></i>
            <p className="cookieHeading">Wir verwenden Cookies.</p>
            <p className="cookieDescription">Wir verwenden Cookies, um sicherzustellen, dass wir dir die beste Erfahrung
                auf unserer Website bieten. Funktionale Cookies, die für den reibungslosen Betrieb unserer Website notwendig sind, werden automatisch akzeptiert.
            </p>
            <a className="cookieLink" href="#">Cookie-Richtlinien lesen</a>
            <div className="buttonContainer">
                <button className="acceptButton" onClick={onAccept}>Akzeptieren</button>
                <button className="declineButton" onClick={onReject}>Ablehnen</button>
            </div>
        </div>
    );
}

export default CookieNotice;
