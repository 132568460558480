import React, {useEffect, useState} from 'react';
import '../../scss/fines.scss';
import ReactGA from 'react-ga';
import Cookies from 'js-cookie';
import axios from "axios";

function FineCatalog() {
    const [selectedLawBooks, setSelectedLawBooks] = useState(['Alle']);
    const [selectedParagraphs, setSelectedParagraphs] = useState([]);

    const [highestBail, setHighestBail] = useState(0);
    const [totalFine, setTotalFine] = useState(0);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [currentResultIndex, setCurrentResultIndex] = useState(0);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const cookieConsent = Cookies.get('cookieConsent');

        if (cookieConsent === 'accepted') {
            ReactGA.initialize(process.env.REACT_APP_API_KEY);
            ReactGA.pageview('/finecatalog');
        }

        updateFavorites()
    }, []);

    useEffect(() => {
        updateHighestBailAndTotalFine();
    }, [selectedParagraphs, selectedLawBooks]);

    useEffect(() => {
        handleSearch();
    }, [searchTerm]);

    useEffect(() => {
        const getGoogleSheetsData = async () => {
            try {
                const response = await axios.get(
                    `https://sheets.googleapis.com/v4/spreadsheets/${process.env.REACT_APP_GOOGLE_SHEETS_FINE}/values/Kompakt!A1:K?key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                );
                // Extrahiere die Daten aus der Antwort
                const rawData = response.data.values;

                // Formatieren der Rohdaten
                const formattedData = rawData.map(item => ({
                    'Gesetzbuch': item[0],
                    '§': item[1], // §
                    'Name': item[2], // Name
                    'Anmerkung': item[3], // Anmerkung
                    'FIB': item[4], // FIB
                    'Gericht': item[5], // Gericht
                    'Strafregister Eintrag': item[6], // Strafregister Eintrag
                    'Ausschließungen §': item[7], // Ausschließungen §
                    'Kaution in $': item[8], // Kaution in $
                    'Geldstrafe in $': item[9], // Geldstrafe in $
                    'Freiheitsstrafe': item[10] // Freiheitsstrafe
                }));

                // Setzen der formatierten Daten in den Zustand
                setData(formattedData);

                // Loggen der Daten zur Überprüfung
                console.log(formattedData);

                setLoading(false);
            } catch (error) {
                console.error('Fehler beim Abrufen der Daten', error);
                setError(error);
                setLoading(false);
            }
        };

        getGoogleSheetsData();
    }, []);

    useEffect(() => {
        console.log(data); // Loggen Sie data in einem Effekt
    }, [data]); // Überwachen Sie data, damit der Effekt bei jeder Aktualisierung ausgeführt wird


    const handleSearch = () => {
        const paragraphs = document.querySelectorAll('tr.paragraph');
        const foundParagraphs = [];
        const paragraphsToHide = [];

        ReactGA.event({
            category: 'Interactions',
            action: 'Search',
            label: `A user searched in table`,
        });

        paragraphs.forEach((paragraph, index) => {
            let paragraphText = paragraph.querySelector('.data-name').innerText.toLowerCase()
            let book = paragraph.querySelector('.bookTag').innerText.toLowerCase()
            let numericParagraph = paragraph.querySelector('.numericParagraph').innerText.toLowerCase()
            let term = searchTerm.toLowerCase()
            if (paragraphText.includes(term) || book.includes(term) || numericParagraph.includes(term)) {
                foundParagraphs.push(paragraph);
            } else {
                paragraphsToHide.push(paragraph);
            }
        });

        if (!searchTerm) {
            paragraphsToHide.length = 0;
            foundParagraphs.push(...paragraphs);
        }

        paragraphsToHide.forEach(paragraph => {
            paragraph.style.display = 'none';
        });

        foundParagraphs.forEach(paragraph => {
            paragraph.style.display = 'table-row';
        });

        setSearchResults(foundParagraphs);
    };


    const updateHighestBailAndTotalFine = () => {
        let maxBail = 0;
        let sumFine = 0;

        selectedParagraphs.forEach(paragraph => {

            let bail = parseFloat(paragraph.querySelector('.bail').innerText) * 1000
            let fine = parseFloat(paragraph.querySelector('.fine').innerText) * 1000

            if (!isNaN(bail) && bail > maxBail) {
                maxBail = bail;
            }

            if (!isNaN(fine)) {
                sumFine += fine;
            }
        });

        setHighestBail(maxBail);

        if (sumFine > 100000) {
            sumFine = 100000;
        }
        setTotalFine(sumFine);
    };

    const generateStars = (wantedCount) => {
        if (wantedCount === 0) {
            return 'Keine Sterne';
        } else if (/^\d+$/.test(wantedCount)) {
            const count = parseInt(wantedCount, 10);
            if (count >= 0 && count <= 5) {
                return '⭐'.repeat(count);
            } else {
                return wantedCount;
            }
        } else if (/^\d+-\d+$/.test(wantedCount)) {
            // Wenn wantedCount ein Bereich ist, z.B. "2-4"
            const [start, end] = wantedCount.split('-').map(Number);
            if (start >= 0 && start <= 5 && end >= 0 && end <= 5) {
                if (start === 0) {
                    return 'Keine Sterne - ' + '⭐'.repeat(end);
                } else {
                    return '⭐'.repeat(start) + '-' + '⭐'.repeat(end);
                }
            } else {
                return wantedCount;
            }
        } else {
            return wantedCount;
        }
    };

    const toggleParagraph = (e) => {
        let tableRow = e.target;

        while (!tableRow.classList.contains('paragraph')) {
            tableRow = tableRow.parentNode;
        }

        if (!e.target.classList.contains('bi-star')) {
            tableRow.classList.toggle('selected');
            setSelectedParagraphs(document.querySelectorAll('tr.selected'));
        }
    };

    const filteredData = selectedLawBooks.length > 0 && !selectedLawBooks.includes('Alle')
        ? data.filter(item => selectedLawBooks.includes(item['Gesetzbuch']))
        : data;

    const toggleLawBook = lawBook => {
        if (!searchTerm) {
            if (selectedLawBooks.length === 1 && selectedLawBooks.includes(lawBook)) {
                setSelectedLawBooks(['Alle']);
            } else if (lawBook === 'Alle') {
                setSelectedLawBooks(['Alle']);
            } else {
                if (selectedLawBooks.includes('Alle')) {
                    setSelectedLawBooks([lawBook]);
                } else {
                    if (selectedLawBooks.includes(lawBook)) {
                        setSelectedLawBooks(selectedLawBooks.filter(book => book !== lawBook));
                    } else {
                        setSelectedLawBooks([...selectedLawBooks, lawBook]);
                    }
                }
            }
        }
    };

    const isLawBookSelected = (book) => {
        let isSelected = false
        selectedLawBooks.forEach((selectedBook) => {
            if (book === selectedBook) isSelected = true
        })
        return isSelected
    }

    const generateWantedText = () => {
        let wantedtext = ''
        selectedParagraphs.forEach((paragraph) => {
            let book = paragraph.querySelector('.bookTag').innerText
            let numericParagraph = paragraph.querySelector('.numericParagraph').innerText

            if (!wantedtext.includes(book)) wantedtext += book + ' '
            wantedtext += numericParagraph + ' '
        })

        if (includeSection) {
            return wantedtext + " + §34"
        } else {
            return wantedtext
        }
    }

    const consultFIBorGOV = () => {
        let consult = {
            'FIB': false,
            'GOV': false,
            'Fine': false
        };

        selectedParagraphs.forEach((paragraph) => {
            let FIB = paragraph.querySelector('.fib').innerText;
            if (FIB === "Ja") {
                consult.FIB = true;
            }

            let GOV = paragraph.querySelector('.gov').innerText;
            if (GOV === "Ja") {
                consult.GOV = true;
            }

            let Fine = paragraph.querySelector('.registerFine').innerText;
            if (Fine === "Ja") {
                consult.Fine = true;
            }
        })
        return consult;
    }

    const generateFineText = (fineAmount) => {
        const numberFormatOptions = {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
        };

        const amount = new Intl.NumberFormat('en-US', numberFormatOptions).format(fineAmount);

        if (fineAmount <= 20000) {
            return amount;
        } else {
            const threshold = 20000; // Schwellenwert für die Aufteilung der Geldstrafe
            const numberOfFines = Math.floor(fineAmount / threshold);
            const remainingAmount = fineAmount % threshold;

            let fineText = '';

            if (numberOfFines > 0) {
                fineText += `${numberOfFines} x ${new Intl.NumberFormat('en-US', numberFormatOptions).format(threshold)}`;
            }

            if (remainingAmount > 0) {
                if (numberOfFines > 0) {
                    fineText += ` + `;
                }
                fineText += new Intl.NumberFormat('en-US', numberFormatOptions).format(remainingAmount);
            }

            return `${amount} (${fineText})`;
        }
    };

    const copyToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");

        const clipboardButton = document.querySelector(".copy-button");

        const originalIcon = clipboardButton.innerHTML;
        clipboardButton.innerHTML = '<i class="bi bi-clipboard-check"></i>';

        clipboardButton.classList.add("copy-success");

        ReactGA.event({
            category: 'Interactions',
            action: 'Law-Text Copied',
            label: `Law-Text was copied`,
        });

        setTimeout(() => {
            clipboardButton.classList.remove("copy-success");
            clipboardButton.innerHTML = originalIcon;
        }, 1000);
    };

    const clearParagraphs = () => {
        const selectedElements = document.querySelectorAll('.selected');

        selectedElements.forEach(element => {
            element.classList.remove('selected');
        });

        setSelectedParagraphs([]);
        ReactGA.event({
            category: 'Interactions',
            action: 'Selection Cleared',
            label: 'All Selections Cleared',
        });
    }

    const calculateStarsForSelectedParagraphs = () => {
        let totalStars = 0;
        selectedParagraphs.forEach((paragraph) => {
            const stars = parseInt(paragraph.querySelector('.wanteds').title, 10);
            if (!isNaN(stars) && stars >= 0 && stars <= 5) {
                totalStars += stars;
            }
        });
        return totalStars <= 5 ? '⭐'.repeat(totalStars) : `⭐⭐⭐⭐⭐`;
    };

    const wasPrevSelected = (item) => {
        let wasSelected = false
        let itemBook = item['Gesetzbuch'].split('|')[1].trim()
        selectedParagraphs.forEach((paragraph) => {
            let paragraphBook = paragraph.classList[0].split('-')[1].trim()
            if (itemBook !== paragraphBook) return;
            if (item['§'] !== paragraph.querySelector('.numericParagraph').innerText) return;
            wasSelected = true
        })
        return wasSelected
    }

    const [includeSection, setIncludeSection] = useState(false);

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setIncludeSection(isChecked);
    };

    const lawBooks = [...new Set(data.map(item => item['Gesetzbuch']))];

    const toggleFavorite = (item) => {
        let favorites = JSON.parse(localStorage.getItem('favorites')) ?? []

        if (favorites.some(function (e) {
            return e.Name === item.Name;
        })) {
            favorites = removeArrayElement(favorites, item)
        } else {
            favorites.push(item)
        }
        localStorage.setItem('favorites', JSON.stringify(favorites))
        updateFavorites()
    }

    const removeArrayElement = (array, element) => {
        for (let key in array) {
            if (array[key].Name === element.Name) {
                array.splice(key, 1);
            }
        }
        return array
    }

    const updateFavorites = () => {
        let favorites = JSON.parse(localStorage.getItem('favorites')) ?? []
        let paragraphs = document.querySelectorAll('.paragraph')

        paragraphs.forEach((element => {
            element.querySelector('.favoriteTag').classList.remove('selected')

            favorites.forEach((fav) => {
                if (element.querySelector('.data-name').innerText.toLowerCase().includes(fav.Name.toLowerCase().trim())) {
                    element.querySelector('.favoriteTag').classList.add('selected')
                    return;
                }
            })
        }))
    }

    const toggleFavorites = (e) => {
        e.target.classList.toggle('selected')

        let paragraphs = document.querySelectorAll('.paragraph')

        paragraphs.forEach((element => {
            element.style.display = 'table-row';

            if(!e.target.classList.contains('selected')) return;
            if(element.querySelector('.favoriteTag').classList.contains('selected')) return;
            element.style.display = 'none';
        }))
    }

    const determineBailStatus = () => {
        const isBailRequired = highestBail > totalFine * 2;
        if (highestBail === 0) {
            return null;
        }
        return isBailRequired ?
            (<p className="special-info" data-status={'BAIL-3'}>
                    KAUTION ERFORDERLICH
                </p>)
            :
            (<p className="special-info" data-status={'BAIL-2'}>
                    KAUTION AUSSETZBAR
                </p>);
    };

    if (loading) {
        return (
            <div className="fine-table">
                <table>
                    <thead>
                    <tr>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Lädt..</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    if (error) {
        return (
            <div className="fine-table">
                <table>
                    <thead>
                    <tr>
                        <th>Fehler</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{error.message}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <>
            <div className="fine-table">
                <div className="filter">
                    <div className="filter-buttons">
                        <button
                            className={'book-ArbG'}
                            onClick={(e) => toggleFavorites(e)}
                        >
                            Favoriten
                        </button>
                        {/*{lawBooks.map((book, index) => (*/}
                        {/*    <button*/}
                        {/*        key={index}*/}
                        {/*        className={`book-${book.split('|')[1].trim()} ${isLawBookSelected(book) ? 'active' : ''}`}*/}
                        {/*        onClick={() => toggleLawBook(book)}*/}
                        {/*    >*/}
                        {/*        {book}*/}
                        {/*    </button>*/}
                        {/*))}*/}
                    </div>
                    <div>
                        <input
                            type="text"
                            className="filter-search-bar"
                            placeholder="Suchen..."
                            maxLength="20"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>
                <table>
                    <thead>
                    <tr>
                        <th>§</th>
                        <th>Name</th>
                        <th>Anmerkung</th>
                        <th>FIB</th>
                        <th>Gericht</th>
                        <th>Strafregister Eintrag</th>
                        <th>Ausschließungen §</th>
                        <th>Kaution in $</th>
                        <th>Geldstrafe in $</th>
                        <th>Freiheitsstrafe</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item, index) => (
                        <tr
                            key={index}
                            className={`book-${item['Gesetzbuch'].split('|')[1].trim()} paragraph ${filteredData.includes(item) ? '' : 'hidden'} ${wasPrevSelected(item) ? 'selected' : ''}`}
                            onClick={(e) => toggleParagraph(e)}
                        >
                            <td className="no-break-table numericParagraph">{item['§']}</td>
                            <td className="data-name">{item.Name}
                                <div
                                    className={`bookTag ${item['Gesetzbuch'].split('|')[1].trim()}`}>{`${item['Gesetzbuch'].split('|')[1].trim()}`}</div>
                                <div className="favoriteTag" onClick={() => toggleFavorite(item)}>
                                    <i className="bi bi-star"/>
                                </div>
                            </td>
                            <td className="no-break-table">{item.Anmerkung}</td>
                            <td className={'fib'}>{item.FIB}</td>
                            <td className={'gov'}>{item.Gericht}</td>
                            <td className={'registerFine'}>{item['Strafregister Eintrag']}</td>
                            <td className="no-break-table">{item['Ausschließungen §']}</td>
                            <td className={'bail'}>{item['Kaution in $']}</td>
                            <td className={'fine'}>{item['Geldstrafe in $']}</td>
                            <td className="wanteds no-break-table"
                                title={item['Freiheitsstrafe']}>{generateStars(item['Freiheitsstrafe'])}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="copy-section-div">
                    {/*<div className="miranda-warning">*/}
                    {/*    <p>Sie haben das Recht zu schweigen. Alles was Sie sagen, kann und wird vor Gericht gegen Sie verwendet werden. </p>*/}
                    {/*    <p>Sie haben das Recht, zu jeder Vernehmung einen Verteidiger hinzuzuziehen. Wenn Sie sich keinen Verteidiger leisten können, wird Ihnen einer gestellt.</p>*/}
                    {/*</div>*/}
                    {selectedParagraphs.length > 0 && (
                        <>
                            <div className={'copy-consult'}>
                                {calculateStarsForSelectedParagraphs() && (
                                    <p className="special-info"
                                       data-status={'STAR'}>Sterne: {calculateStarsForSelectedParagraphs()}</p>
                                )}
                                {determineBailStatus() && (
                                    <>
                                        <p className="special-info"
                                           data-status={'BAIL'}>Kaution: {new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            maximumFractionDigits: 0
                                        }).format(highestBail)}</p>
                                        {determineBailStatus()}
                                    </>
                                )}
                                {consultFIBorGOV().FIB && <p className="special-info" data-status={'FIB'}>FIB</p>}
                                {consultFIBorGOV().GOV && <p className="special-info" data-status={'GOV'}>GERICHT</p>}
                                {consultFIBorGOV().Fine && <p className="special-info" data-status={'FINE'}>Strafregister</p>}
                            </div>
                            <div className="copy-section">
                                <button className="clear-button" onClick={() => clearParagraphs()}>
                                    <i className={'bi bi-trash-fill'}></i>
                                </button>
                                <p className={`${selectedParagraphs.length > 0 && 'copy-paragraphs'}`}>
                                    <p className={'wanted-text'} title={generateWantedText()}
                                       onClick={() => copyToClipboard(generateWantedText())}>{generateWantedText()}</p>
                                    {selectedParagraphs.length > 0 &&
                                        <button className="copy-button"
                                                onClick={() => copyToClipboard(generateWantedText())}><i
                                            className={'bi bi-clipboard'}/></button>}
                                </p>
                                <div>
                                    <p className={"copy-fine"}>Geldstrafe: {generateFineText(totalFine)}</p>
                                    <p className={"copy-fine-info"}>Strafmilderung: §31.3 Ersatzhaft: §35.6</p>
                                </div>
                                {/*<div className="section-checkbox-div">*/}
                                {/*    <input type="checkbox" id="ccp-checkbox" onChange={handleCheckboxChange}/>*/}
                                {/*    <label htmlFor="ccp-checkbox">+ §34</label>*/}

                                {/*    <input type="checkbox" id="straf-checkbox" onChange={handleCheckboxChange}/>*/}
                                {/*    <label htmlFor="straf-checkbox">+ Strafmilderung</label>*/}
                                {/*</div>*/}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default FineCatalog;
